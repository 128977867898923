import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

const Emoji = ({ src, children }) => {

  const emojis = [
    { name: "success", alt: "Bravo, vous avez tout juste !" },
    { name: "fail", alt: "Aïe ! Vous vous êtes trompé, ça sera pour la prochaine fois !" },
    { name: "lesson", alt: "Prendre le temps de lire c'est apprendre" },
    { name: "question", alt: "Concentrez-vous pour trouver la bonne réponse !" },
    { name: "files", alt: "Vous avez débloqué de nouvelles fiches techniques" },
    { name:"congrats", alt: "Bravo ! Vous avez réussi le défi de la semaine !" }
  ]

  const [state, setState] = useState(0);
  const [animate, setAnimate] = useState(false);


  useEffect(() => {
    setState(0);

    const timer = setTimeout(() => {
      setAnimate(true);
    }, 10);

    let timeout = setTimeout(() => {
      setState(1);
    }, 50)

    let end = setTimeout(() => {
      setAnimate(false);
    }, 250)

    return () => {
      clearTimeout(timer);
      clearTimeout(timeout);
      clearTimeout(end);
    };
  }, [src])
  return (
    <img src={children ? "../assets/images/emojis/" + src + ".webp" : "./assets/images/emojis/" + src + ".webp"} alt="" className={`${state === 0 ? "emoji" : "emoji fat"} ${animate ? "animate" : ""}`} />
  );
};

export default Emoji;