import React, { useEffect, useState } from 'react';
import RoundPictures from './RoundPictures';

const SlideRoundQuestion = ({ slide, setPoints, points, changeState, setRight }) => {

  const [answers, setAnswers] = useState(JSON.parse(slide.round_answers));
  const [userAnswers, setUserAnswers] = useState([]);
  const [opened, setOpened] = useState(null);

  const correction = JSON.parse(slide.round_correction);

  const closePopup = () => {
    setOpened(null);
  }

  const changeUserAnswers = (index, value) => {
    // Créer une copie du tableau
    let newArray = [...userAnswers];

    // Modifier la deuxième valeur dans la copie
    newArray[parseInt(index)] = parseInt(value);

    // Mettre à jour l'état avec la nouvelle copie du tableau
    setUserAnswers(newArray);
  }

  const handleValidate = () => {
    if (JSON.stringify(correction) === JSON.stringify(userAnswers)) {
      setPoints(points + 20);
      changeState();
    }
    else {
      setRight(false);
      changeState();
    }
  }

  useEffect(() => {
    if (parseInt(slide.round_inputs) === 4) {
      setUserAnswers([null, null, null, null]);
    }
  }, [])

  return (
    <article className={"tplt-" + slide.template}>
      <div class="question" dangerouslySetInnerHTML={{ __html: slide.question }}>

      </div>
      {
        parseInt(slide.round_inputs) === 4 ?
          <div className='round-four'>
            <button className="round-input" onClick={() => setOpened(0)}>
              {
                userAnswers[0] === null ?
                  "?"
                  :
                  <img src={answers[userAnswers[0]]} alt="" />
              }
            </button>
            <div>
              <button className="round-input" onClick={() => setOpened(3)}>
                {
                  userAnswers[3] === null ?
                    "?"
                    :
                    <img src={answers[userAnswers[3]]} alt="" />
                }
              </button>
              <img src={slide.round_image} alt="" />
              <button className="round-input" onClick={() => setOpened(1)}>
                {
                  userAnswers[1] === null ?
                    "?"
                    :
                    <img src={answers[userAnswers[1]]} alt="" />
                }
              </button>
            </div>
            <button className="round-input" onClick={() => setOpened(2)}>
              {
                userAnswers[2] === null ?
                  "?"
                  :
                  <img src={answers[userAnswers[2]]} alt="" />
              }
            </button>
          </div>
          :
          ""
      }
      {
        opened !== null ?
          <RoundPictures answers={answers} opened={opened} closePopup={closePopup} changeUserAnswers={changeUserAnswers} />
          :
          ""
      }

      <button onClick={handleValidate}>
        Valider
      </button>
    </article>
  );
};

export default SlideRoundQuestion;