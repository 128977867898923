import React, { useEffect, useState } from 'react';

const SelectV2Popup = () => {

  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    if (!isOpened) {
      let timeout = window.setTimeout(() => {
        setIsOpened(true);
        return () => {
          clearInterval(timeout)
        }
      }, 100)
    }
  }, [])

  return (
    <div>
      <ul>

      </ul>
    </div>
  );
};

export default SelectV2Popup;