import React from 'react';
import Circle from '@uiw/react-color-circle';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useEffect } from 'react';

const Color = ({ hex, setHex, user, setUser, isConnected }) => {

  const navigate = useNavigate();
  const [localeColor, setLocaleColor] = useState(user.color);
  const handleValidateColor = () => {
    axios.post('https://connivence-salon.net/api/e-learning/users/color.php', new URLSearchParams({
      color: localeColor,
      id: user.id
    }))
      .then(function (response) {
        // Créer une copie de l'objet userData
        const updatedUser = { ...user };

        // Mettre à jour la propriété "color" dans la copie
        updatedUser.color = localeColor;

        // Mettre à jour le state avec la nouvelle copie
        setUser(updatedUser);

        localStorage.setItem("user", JSON.stringify([updatedUser]));

        setHex(localeColor);

        navigate("/home");
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    isConnected();

    if(document.body.classList.contains("fingers"))
      document.body.classList.remove("fingers");
  }, [])
  return (
    <>
      <Header />
      <main className='color-container' style={{ '--main-color': localeColor }}>
        <h1>Votre couleur préférée</h1>
        <Circle
          colors={['#191919', '#2c3e50', '#34495e', '#1260cc', '#3498db', '#16a085', '#1abc9c', '#27ae60', '#2ecc71', '#f1c40f', '#f39c12', '#e67e22', '#d35400', '#e74c3c', '#c0392b', '#FF69B4', '#9b59b6', '#8e44ad']}
          color={localeColor}
          onChange={(color) => {
            setLocaleColor(color.hex);
          }}
        />
        <button onClick={handleValidateColor} style={{ "backgroundColor": localeColor, "borderColor": localeColor }}>
          <p>Confirmer</p>
          <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.0769 2L23 11.0011L14.0769 20M2 11.0034H23" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>
      </main>
      <Footer visible={false} />
    </>
  );
};

export default Color;