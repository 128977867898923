import React from 'react';
import SelectV2Popup from './SelectV2Popup';

const SelectsContainerV2 = ({ changeUserAnswers, label, answers, selectIndex, values }) => {
  return (
    <>
    <div className='fake-label' dangerouslySetInnerHTML={{ __html: label }}></div>
      <button className='select-button'>
        ???
      </button>
      <SelectV2Popup />
    </>
    
  );
};

export default SelectsContainerV2;