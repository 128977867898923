import React, { useEffect, useState } from 'react';

const AnimationHalo = ({slide}) => {

  const [state, setState] = useState("halo");

  useEffect(() => {
    setState("halo");
    let timeout = setTimeout(() => {
      setState("halo go");
      return () => {
        clearTimeout(timeout);
      }
    }, 100)

    let timeoutbis = setTimeout(() => {
      setState("halo invisible");
      return () => {
        clearTimeout(timeoutbis);
      }
    }, 1100)
  }, [slide])

  return (
    <div className='halo-container'>
      <div className={state}>
      
      </div>
    </div>
    
  );
};

export default AnimationHalo;