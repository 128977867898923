import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Heart from '../components/Heart';

const Welcome = ({ newUser, setNewUser, isConnected, user }) => {

  useEffect(() => {
    isConnected();

    if (document.body.classList.contains("fingers"))
      document.body.classList.remove("fingers");
  }, [])

  return (
    <>
      <Header />
      <main className="welcome-component">
        <Heart />
        {newUser ?
          <>
            <h1>Bienvenue <br /><strong>{user.prenom}</strong> !</h1>
            <p>
              Connivence Academy, <strong>c’est votre application</strong> ! Vous pouvez la personnaliser en <strong>choisissant votre couleur préférée</strong> !
            </p>
          </>
          :
          <>
            <h1>Vous revoilà <br /><strong>{user.prenom}</strong> !</h1>
            <p>
              Le <strong>défi de la semaine</strong> est maintenant disponible !
            </p>
          </>
        }
        <Link to="/home" className='round-btn'>
          C'est parti
          <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.0769 2L23 11.0011L14.0769 20M2 11.0034H23" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </Link>
      </main>
      <Footer visible={false} />
    </>
  );
};

export default Welcome;