import axios from 'axios';
import React, { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';

const Login = ({ user, setUser, getUser }) => {

  const mobile = useRef();

  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handlerMobileInput = (ev) => {
    let inputValue = ev.target.value.replace(/\D/g, ''); // Retirer tous les caractères non numériques
    let formattedValue = '';

    if (inputValue.length <= 15) {
      for (let i = 0; i < inputValue.length; i += 2) {
        formattedValue += inputValue.substr(i, 2);
        if (i + 2 < inputValue.length) {
          formattedValue += ' ';
        }
      }

      ev.target.value = formattedValue.trim();
    }
  }

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    let respUser = await getUser(mobile.current.value);

    console.log(respUser)

    if (respUser) {
      sessionStorage.setItem("logged", "0");
      window.location.href = "/pin";
    }
    else {
      setError("Aucun compte n'a été trouvé avec ce numéro téléphone");
    }
  }

  useEffect(() => {
    if(!document.body.classList.contains("fingers"))
      document.body.classList.add("fingers");
    
    if (user.id) {
      sessionStorage.setItem("logged", "0");
      window.location.href = "/pin";
    }
  }, [user])

  return (
    <>
      <Header />
      <main className="login-component">
        <h1>Votre numéro de téléphone</h1>
        <form onSubmit={handleSubmit}>
          <input ref={mobile} className="large-input" id="phone-number" maxLength="14" pattern="\d{2} \d{2} \d{2} \d{2} \d{2}" type="text" onInput={handlerMobileInput} placeholder="01 23 45 67 89" />
          <button>
            Valider
            <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.0769 2L23 11.0011L14.0769 20M2 11.0034H23" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
        </form>
      </main>
      <Footer visible={false} />
    </>
  );
};

export default Login;