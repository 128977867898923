import axios from 'axios';
import React, { useEffect } from 'react';
import { useState } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import RanksItem from '../components/RanksItem';

const Ranking = ({ isConnected, user }) => {

  const [ranks, setRanks] = useState([]);
  const [myRank, setMyRank] = useState([]);

  useEffect(() => {

    if (!user || !user.defi) {
      console.warn("L'objet user n'est pas encore disponible.");
      return;
    }
    
    isConnected();

    if(!document.body.classList.contains("fingers"))
      document.body.classList.add("fingers");

    axios.get('https://connivence-salon.net/api/e-learning/users/ranking.php', {
      params: {
        user: user?.id
      }
    })
      .then(response => {
        if (response.data) {
          setRanks(response.data.all);
          setMyRank(response.data.me);
        }
        // Traiter la réponse ici
      })
      .catch(error => {
        console.error('Erreur:', error);
        // Gérer l'erreur ici
      });
  }, [user])
  return (
    <>
      <Header />
      <main className="ranking-component">
        <h1>Classement</h1>

        <ul className='ranking'>
          <RanksItem key={0} data={myRank[0]} mine={true} user={user} position={myRank.rank} />
          {ranks?.map((rank, index) => <RanksItem key={index + 1} position={index + 1} data={rank} mine={false} user={user} />)}
        </ul>
      </main>
      <Footer visible={true} active={"profile"} />
    </>
  );
};

export default Ranking;