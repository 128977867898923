import React, { useState } from 'react';
import { useEffect } from 'react';

const RoundPictures = ({ answers, opened, closePopup, changeUserAnswers }) => {
  const [isOpened, setIsOpened] = useState(false);

  const handlerClose = (ev) => {
    if (ev.target.classList.contains("round-pictures")) {
      setIsOpened(false);

      let timeout = window.setTimeout(() => {
        closePopup();

        return () => {
          clearTimeout(timeout);
        }
      }, 300)
    }

  }

  const handlePictureClick = (ev) => {
    setIsOpened(false);
    changeUserAnswers(opened, ev.target.dataset.id);

    let timeout = window.setTimeout(() => {
      closePopup();

      return () => {
        clearTimeout(timeout);
      }
    }, 300)
  }

  useEffect(() => {
    if (!isOpened) {
      let timeout = window.setTimeout(() => {
        setIsOpened(true);

        return () => {
          clearInterval(timeout)
        }
      }, 100)
    }
  }, [])

  return (
    <div className={isOpened ? "round-pictures visible" : "round-pictures"} onClick={handlerClose}>
      <div>
        <h3>Choisissez l'image qui correspond...</h3>
        <div>
          {
            answers?.map((answer, index) => (
              <button key={index} data-id={index} className='round-pictures-button' onClick={handlePictureClick}>
                <img src={answer} alt="" />
              </button>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default RoundPictures;