import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
//import { HTML5Backend } from 'react-dnd-html5-backend';
import Home from './pages/Home';
import Color from './pages/Color';
import { useState } from 'react';
import { useEffect } from 'react';
import Login from './pages/Login';
import Pin from './pages/Pin';
import Welcome from './pages/Welcome';
import Lessons from './pages/Lessons';
import Profile from './pages/Profile';
import Ranking from './pages/Ranking';
import Achievements from './pages/Achievements';
import axios from 'axios';
import Game from './pages/Game';
import File from './pages/File';
import Pot from './pages/Pot';
import Account from './pages/Account';
import Emoji from './components/Emoji';
import Download from './pages/Download';

function App() {

  const medals = [
    {
      name: "Connaissance",
      desc: "Le nombre de défis que j'ai réussi !",
      breakpoints: [1, 3, 5, 10, 20, 52],
      alt: "Votre badge Connaissance"
    },
    {
      name: "Persévérance",
      desc: "Le nombre de fois où je me suis trompé mais j'ai persévéré.",
      breakpoints: [1, 8, 20, 40, 80, 150],
      alt: "Votre badge Persévérance"
    },
    {
      name: "Rapidité",
      desc: "Le nombre de semaines où j'ai été dans les trois plus rapides à réussir un défi.",
      breakpoints: [1, 3, 6, 10, 15, 26],
      alt: "Votre badge Rapidité"
    },
    {
      name: "Rayonnement",
      desc: "Le nombre d'utilisateurs que j'ai parrainé.",
      breakpoints: [1, 3, 6, 10, 40, 100],
      alt: "Votre badge Rayonnement"
    }
  ]

  const [hex, setHex] = useState('#e21617');
  const [newUser, setNewUser] = useState(false);
  const [user, setUser] = useState([]);
  const [datasLoaded, setDatasLoaded] = useState(false);

  const isConnected = () => {
    console.log(JSON.parse(localStorage.getItem("user")))
    if (!localStorage.getItem("user")) {
      window.location.href = "/login";
    }
    else if (Array.isArray(JSON.parse(localStorage.getItem("user"))) && JSON.parse(localStorage.getItem("user")).length === 0) {
      window.location.href = "/login";
    }
    else if (!sessionStorage.getItem("logged")) {
      window.location.href = "/pin";
    }
    else if (parseInt(sessionStorage.getItem("logged")) === 0 || parseInt(sessionStorage.getItem("logged")) < parseInt(Date.now())) {
      window.location.href = "/pin";
    }
  }

  const getUser = async (telephone, lang) => {
    try {
      const response = await axios.post('https://connivence-salon.net/api/e-learning/users/retrieve.php', new URLSearchParams({
        telephone: telephone,
        lang: lang ? lang : "fr"
      }));

      let resp = response.data.content;
      console.log(resp);
      setUser(resp[0]);

      if (resp.length > 0) {
        localStorage.setItem("user", JSON.stringify(resp));
        return true;
      } else {
        if (localStorage.getItem("user")) {
          localStorage.removeItem("user");
        }
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }


  useEffect(() => {
    if (!datasLoaded) {
      if (localStorage.getItem("user")) {
        setUser(JSON.parse(localStorage.getItem("user"))[0]);
        setHex(JSON.parse(localStorage.getItem("user"))[0].color);
        setDatasLoaded(true);
      }
    }

  }, []);
  return (
    <div id="application" style={{ '--main-color': hex }}>

      <div className='only-for-mobile'>
        <Emoji src="chokbar" />
        <p>Pour l'instant, l'application est uniquement disponible sur mobile en portrait !</p>
      </div>

      <div className='fake-bg'></div>

      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home user={user} isConnected={isConnected} />} />
          <Route path='/my-color' element={<Color hex={hex} setHex={setHex} user={user} setUser={setUser} isConnected={isConnected} />} />
          <Route path='/login' element={<Login user={user} setUser={setUser} getUser={getUser} />} />
          <Route path='/pin' element={<Pin user={user} setUser={setUser} getUser={getUser} />} />
          <Route path='/welcome' element={<Welcome newUser={newUser} setNewUser={setNewUser} user={user} isConnected={isConnected} />} />
          <Route path='/lessons' element={<Lessons isConnected={isConnected} user={user} setUser={setUser} getUser={getUser} />} />
          <Route path='/file/:fileId' element={<File isConnected={isConnected} />} />
          <Route path='/profile' element={<Profile user={user} isConnected={isConnected} />} />
          <Route path='/ranking' element={<Ranking isConnected={isConnected} user={user} />} />
          <Route path='/achievements' element={<Achievements isConnected={isConnected} medals={medals} user={user} />} />
          <Route path='/game' element={<Game isConnected={isConnected} user={user} medals={medals} getUser={getUser} />} />
          <Route path='/pot' element={<Pot isConnected={isConnected} user={user} setUser={setUser} />} />
          <Route path='/account' element={<Account user={user} isConnected={isConnected} />} />
          <Route path='/download' element={<Download />} />
          <Route path='*' element={<Home user={user} isConnected={isConnected} />} />
        </Routes>
      </BrowserRouter>
    </div>

  );
}

export default App;