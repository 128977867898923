import React, { useEffect, useState } from 'react';
import Header from '../components/Header';

const Download = () => {

  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [state, setState] = useState("");

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        setDeferredPrompt(null);
      });
    }
  };

  useEffect(() => {
    if(!document.body.classList.contains("fingers"))
      document.body.classList.add("fingers");
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    });

    let timeout = setTimeout(() => {
      setState("loaded");
      return () => {
        clearTimeout(timeout);
      }
    }, 50)
  }, []);

  return (
    <>
      <Header />
      <main className="download-component">
        <div className={state}>
          <h2>Prêt à apprendre la coiffure comme tu ne l'as jamais fait ?</h2>
          <img src="./assets/images/download.svg" alt="Votre plateforme d'E-Learning dans votre poche." />
          <button onClick={handleInstallClick}>Installer l'application</button>
        </div>
      </main>
    </>
  );
};

export default Download;