import React, { useEffect, useState } from 'react';

const Medal = ({ id, data, progress }) => {

  const [level, setLevel] = useState(0);
  const [diff, setDiff] = useState(0);
  const [max, setMax] = useState(0);

  const getLevel = () => {
    if (progress === 0) {
      setLevel(0);
      setMax(0);
      setDiff(data.breakpoints[0]);
    }
    else {
      for (let i = 0; i < data.breakpoints.length; i++) {
        if (i < data.breakpoints.length - 1) {
          if (progress >= data.breakpoints[i] && progress < data.breakpoints[i + 1]) {
            setLevel(i);
            setMax((progress - data.breakpoints[i]) / (data.breakpoints[i + 1] - data.breakpoints[i]) * 100);
            setDiff(data.breakpoints[i + 1] - progress);
            console.log(data.breakpoints[i + 1] - data.breakpoints[i])
            break;
          }
        }
        else {
          setLevel(i);
          setMax(100);
        }
      }
    }

  }

  const getColor = (percentage) => {
    let r, g, b;
    if (percentage < 50) {
      // Interpolate between red and yellow
      r = 226;
      g = Math.round(22 + (percentage / 50) * (238 - 22));
      b = 23;
    } else {
      // Interpolate between yellow and green
      r = Math.round(255 - ((percentage - 50) / 50) * (255 - 3));
      g = Math.round(238 - ((percentage - 50) / 50) * (238 - 192));
      b = 0;
    }
    return `rgb(${r}, ${g}, ${b})`;
  };

  useEffect(() => {
    getLevel();
  }, [progress])

  return (
    <li className={progress === 0 ? "medal progress-nul" : "medal progress-" + String(level)}>
      <div className='main'>
        <h3>{data.name}</h3>
        <img src={"./assets/images/medals/" + String(id) + "-" + String(level) + ".svg"} alt={data.alt} />
        <p>{progress}</p>
      </div>
      <div className='content'>
        <p>{data.desc}</p>
        <div className='jauge-container'>
          <div className='jauge-content' style={{ width: String(max) + "%", backgroundColor: getColor(max) }}></div>
        </div>
        {
          level === data.breakpoints.length - 1 ? "" :

            <p>Plus que {diff} avant d'atteindre le palier suivant.</p>
        }
      </div>
    </li>
  );
};

export default Medal;
