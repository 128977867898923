import axios from 'axios';
import React from 'react';

const SpeedButton = ({img, value, selected, setMySpeed, id }) => {
  const handlerClick = (ev) => {
    axios.post('https://connivence-salon.net/api/e-learning/users/speed.php', new URLSearchParams({
      id, speed: value
    })).then(response => {
      sessionStorage.setItem("speed", value);
      setMySpeed(value);
      /*setIsOpened(false); // Fermer le popup après succès
      closePopup();*/
    }).catch(error => {
      // Gérer l'erreur ici, par exemple en affichant un message d'erreur
      console.error('Erreur lors de l\'envoi de la requête:', error);
      // Peut-être définir un état pour afficher l'erreur dans le popup
    });
  }
  return (
    <li>
      <label className="radio-img">
        <input type="radio" name="speed" defaultChecked={selected} />
        <div onClick={handlerClick} className='animated-emoji-btn'>
          <img src={selected ? `./assets/images/${img}.webp` : `./assets/images/${img}.png`} alt="Emoji d'illustration du bouton" />
        </div>
      </label>
    </li>
  );
};

export default SpeedButton;