import React, { useEffect, useRef, useState } from 'react';

const Paragraph = ({ par, pos, maxPos, visible, changeLocalState, changeState, user }) => {

  const mask = useRef();
  const containerRef = useRef();
  const slideRef = useRef();
  const jauge = useRef();

  const [initd, setInitd] = useState(false);
  const [lessonHeight, setLessonHeight] = useState();
  const [canChange, setCanChange] = useState(false);
  let imgs;

  let loadedImages = 0;

  const initArticle = () => {
    console.log(mask.current);
    mask.current.style.height = String(containerRef.current.offsetHeight) + "px";
    setLessonHeight(containerRef.current.offsetHeight);
    setInitd(true);
  }

  const onImageLoaded = () => {
    loadedImages++;
    if (loadedImages === imgs.length) {
      initArticle();
    }
  };

  useEffect(() => {
    if (visible) {
      slideRef.current.style.display = "block"; // à remettre en dessous si y a des problèmes
      if (initd === false) {
        //slideRef.current.style.display = "block";
        imgs = (containerRef?.current?.querySelectorAll('img'));
        if (imgs.length > 0) {
          imgs.forEach(img => {
            // Si l'image est déjà chargée (en cache par exemple), onImageLoaded est appelé directement
            if (img.complete) {
              onImageLoaded();
            } else {
              img.addEventListener('load', onImageLoaded);
              // N'oubliez pas de retirer l'écouteur d'événements pour éviter les fuites de mémoire
              img.addEventListener('load', () => {
                img.removeEventListener('load', onImageLoaded);
              });
            }
          });
        }
        else {
          initArticle();
        }
      }
      else {
        let prog = 0;
        let rect = 0;
        let screenHeight = window.innerHeight - 30;
        let chrono = setInterval(() => {
          prog++;
          mask.current.style.height = String(lessonHeight - prog) + "px";
          jauge.current.style.width = String(prog / lessonHeight * 100) + "%";
          rect = mask.current.getBoundingClientRect();

          if (screenHeight - (rect.top + window.scrollY) < 0 && window.scrollY < ((rect.top + window.scrollY) - screenHeight)) {
            console.log((rect.top + window.scrollY) - screenHeight);
            window.scrollTo({
              top: (rect.top + window.scrollY) - screenHeight,
              left: 0,
              behavior: 'auto' // Cette ligne est facultative car 'auto' est le comportement par défaut
            });
          }



          if (prog >= lessonHeight) {
            let delay = setTimeout(() => {
              setCanChange(true);
              return () => {
                clearTimeout(delay);
              }
            }, 500)
            let timeout = setTimeout(() => {
              window.scrollTo({
                top: document.body.scrollHeight,
                left: 0,
                behavior: 'smooth' // Cette ligne est facultative car 'auto' est le comportement par défaut
              });
              return () => {
                clearTimeout(timeout);
              }
            }, 510)
            clearInterval(chrono);
          }
        }, parseInt(par?.speed) === 0 && sessionStorage.getItem("speed") ? parseInt(sessionStorage.getItem("speed")) : parseInt(par?.speed))
        //remettre à 80
      }
    }
    else {
      slideRef.current.style.display = "none";
    }
  }, [visible, initd])
  return (
    <div ref={slideRef} className={initd && visible ? 'slide-lesson visible' : 'slide-lesson'}>
      <p className='discreet'>Prendre le temps de lire c'est apprendre...</p>
      <span className='jauge-container'>
        <span ref={jauge} className='jauge-content'>
        </span>
        <p>{pos} / {maxPos}</p>
      </span>
      <div className='paragraph-title' dangerouslySetInnerHTML={{ __html: par.title }}></div>
      <div ref={containerRef} className={"paragraph-content " + par.orientation} dangerouslySetInnerHTML={{ __html: par.content }}></div>
      {pos < maxPos ?
        <button className={canChange ? 'next-slide visible' : "next-slide"} onClick={() => {
          if (canChange) {
            changeLocalState(pos);
          }
        }}>Suivant</button>
        :
        <button className={canChange ? 'next-slide visible' : "next-slide"} onClick={() => {
          if (canChange) {
            changeState();
          }
        }}>Suivant</button>
      }
      <div ref={mask} className='mask'></div>
    </div>
  );
};

export default Paragraph;