import React from 'react';

const SlideClassicQuestion = ({ slide, setPoints, points, changeState, setRight }) => {

  const rightAnswer = () => {
    setPoints(points + 10);
    changeState();
  }

  const wrongAnswer = () => {
    setRight(false);
    changeState();
  }

  return (
    <article className={"tplt-" + slide.template}>
      <div class="question" dangerouslySetInnerHTML={{ __html: slide.question }}>

      </div>
      <ul className={"answers " + slide.answers_display}>
        {
          JSON.parse(slide.answers)?.map((answer, index) => (
            <li className={answer.includes("<img") ? "" : ""} key={index}>
              <button onClick={() => parseInt(slide.good_answer) === index ? rightAnswer() : wrongAnswer()} dangerouslySetInnerHTML={{ __html: answer }}></button>
            </li>
          ))
        }
      </ul>
    </article>
  );
};

export default SlideClassicQuestion;