import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useRef } from 'react';

const PayoutPopup = ({ closePopup, user, setUser, money, setMoney }) => {

  const currency = "EUR";

  const mailRef = useRef();

  const [isOpened, setIsOpened] = useState(false);
  const [state, setState] = useState(0);

  const setSuccess = () => {
    setState(1);

    let timeout = setTimeout(() => {
      setMoney("0.00");
      setIsOpened(false);

      let timeout = window.setTimeout(() => {
        closePopup();

        return () => {
          clearTimeout(timeout);
        }
      }, 300)
      return () => {
        clearTimeout(timeout);
      }
    }, 4000)
  }

  const setError = () => {
    setState(-1);

    let timeout = setTimeout(() => {
      setState(0);
      return () => {
        clearTimeout(timeout);
      }
    }, 4000)
  }

  const getState = () => {
    if (state === 0) {
      return "";
    }
    else if (state === 1) {
      return "success";
    }
    else {
      return "error";
    }
  }

  const handleAddBankDetails = async (ev) => {
    ev.preventDefault();

    let email = mailRef.current.value

    axios.post('https://connivence-salon.net/api/e-learning/paypal/transfer.php', {
      email,
      amount: money,
      currency,
      user: user.id
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if (response.data === "Success") {
        console.log("succes");
        setSuccess()
      }
      else {
        setError();
      }
      /*setIsOpened(false); // Fermer le popup après succès
      closePopup();*/
    }).catch(error => {
      // Gérer l'erreur ici, par exemple en affichant un message d'erreur
      setError();
      console.error('Erreur lors de l\'envoi de la requête:', error);
      // Peut-être définir un état pour afficher l'erreur dans le popup
    });
  };

  const handlerClose = (ev) => {
    if (ev.target.classList.contains("payout-popup")) {
      setIsOpened(false);

      let timeout = window.setTimeout(() => {
        closePopup();

        return () => {
          clearTimeout(timeout);
        }
      }, 300)
    }

  }

  useEffect(() => {
    if (!isOpened) {
      let timeout = window.setTimeout(() => {
        setIsOpened(true);
        return () => {
          clearInterval(timeout)
        }
      }, 100)
    }
  }, [])

  return (
    <div className={isOpened ? "payout-popup visible" : "payout-popup"} onClick={handlerClose}>
      <div>
        {state === 0 ?
          <div className='main-screen'>
            <h3>Récupérer ma cagnotte sur mon compte Paypal :</h3>
            <form onSubmit={handleAddBankDetails}>
              <input ref={mailRef} type="email" placeholder="Votre mail Paypal" required />
              <button type="submit">Récupérer</button>
            </form>
          </div>
          :
          ""
        }
        {state === 1 ?
          <div className='success-screen'>
            <div className="screenAlert-icon screenAlert-success animate">
              <span className="screenAlert-line screenAlert-tip animateSuccessTip"></span>
              <span className="screenAlert-line screenAlert-long animateSuccessLong"></span>
              <div className="screenAlert-placeholder"></div>
              <div className="screenAlert-fix"></div>
            </div>
            <h3 className='callback-message'>Transfert réussi !</h3>
            <p>Votre cagnotte a été transférée sur votre compte Paypal.</p>
          </div>
          :
          ""
        }
        {state === -1 ?
          <div className='fail-screen'>
            <div className="screenAlert-icon screenAlert-error animate">
              <span className="screenAlert-x-mark">
                <span className="screenAlert-line screenAlert-left animateXLeft"></span>
                <span className="screenAlert-line screenAlert-right animateXRight"></span>
              </span>
              <div className="screenAlert-placeholder"></div>
              <div className="screenAlert-fix"></div>
            </div>
            <h3 className='callback-message'>Transfert échoué...</h3>
            <p>Une erreur s'est produite, avez-vous bien écrit votre adresse mail ?</p>
          </div>
          :
          ""
        }
      </div>
    </div>
  );
};

export default PayoutPopup;