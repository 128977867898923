import React, { useEffect, useState } from 'react';

const SlideRoundNumbers = ({ slide, setPoints, points, changeState, setRight }) => {

  const [userAnswers, setUserAnswers] = useState([]);

  const correction = JSON.parse(slide.round_correction);

  const changeUserAnswers = (index, value) => {
    // Créer une copie du tableau
    let newArray = [...userAnswers];

    // Modifier la deuxième valeur dans la copie
    newArray[parseInt(index)] = parseInt(value);

    // Mettre à jour l'état avec la nouvelle copie du tableau
    setUserAnswers(newArray);
  }

  const handleChange = (ev) => {
    if (String(ev.target.value).length >= 1) {
      ev.target.blur();
      if (parseInt(ev.target.dataset.index) < parseInt(slide.round_inputs) - 1) {
        document.querySelector(".number-inputs").querySelectorAll("input")[parseInt(parseInt(ev.target.dataset.index) + 1)].focus();
      }
    }

    changeUserAnswers(parseInt(ev.target.dataset.index), ev.target.value);
  }

  const resetInputs = () => {
    let inputs = document.querySelector(".number-inputs").querySelectorAll("input");

    // Parcours chaque input et met sa valeur à ""
    inputs.forEach(function (input) {
      input.value = "";
    });
  }

  const checkAnswers = () => {
    let ready = true;
    userAnswers.forEach((answer) => {
      if (String(answer).length === 0) {
        ready = false;
      }
    });

    return ready;
  }

  const handleValidate = () => {
    if (userAnswers.length >= JSON.parse(slide.round_correction).length && checkAnswers()) {
      let sortedAnswers = userAnswers;
      sortedAnswers.sort(function (a, b) {
        return a - b;
      });

      if (JSON.stringify(correction) === JSON.stringify(sortedAnswers)) {
        setPoints(points + 20);
        console.log("bon");
        resetInputs();
        changeState();
      }
      else {
        setRight(false);
        resetInputs();
        changeState();
      }
    }
  }

  useEffect(() => {
    setUserAnswers([])
    if (window.scrollY > 0) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth' // Cette ligne est facultative car 'auto' est le comportement par défaut
      });
    }
  }, [slide])

  return (
    <article className={"tplt-" + slide.template}>
      <div className="question" dangerouslySetInnerHTML={{ __html: slide.question }}>

      </div>
      <img src={slide.round_image} alt="" />

      <p className='instructions'>
        Remplissez les cases avec les bons numéros :
      </p>

      <div className='number-inputs'>
        {

          JSON.parse(slide.round_correction).map((value, index) =>
            <span className='input-container' key={index}>
              <label htmlFor={"slide-" + slide.id + "-input-" + parseInt(index + 1)}>N°</label>
              <input min="0" max="50" type='number' name={"slide-" + slide.id + "-input-" + parseInt(index + 1)} id={"slide-" + slide.id + "-input-" + parseInt(index + 1)} data-index={index} onChange={handleChange} data-id={slide.id} className={index === 0 ? "first" : ""} required />
            </span>

          )

        }
      </div>

      <button onClick={handleValidate}>
        Valider
      </button>
    </article>
  );
};

export default SlideRoundNumbers;