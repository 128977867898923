import React, { useEffect, useRef, useState } from 'react';

const RedScreen = () => {

  const [animating, setAnimating] = useState(false);

  const element = useRef(null);

  useEffect(() => {
    if(!animating){
      setAnimating(true)
      setTimeout(() => {
        element.current.style.opacity = "0.9";
      }, 100)
      setTimeout(() => {
        element.current.style.transition = "opacity .5s";
      }, 200)
      setTimeout(() => {
        element.current.style.opacity = "0";
      }, 300)
      setTimeout(() => {
        element.current.style.transition = "opacity .1s";
        setAnimating(true);
      }, 800)
    }
  }, [])

  return (
    <div className='red-screen' ref={element}>
      
    </div>
  );
};

export default RedScreen;